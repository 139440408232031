.learn-more-button {
    @apply px-[50px] hidden md:inline-block text-lg relative py-[15px]  font-bold ml-12 hover:bg-white  hover:text-brand transition-colors duration-150 ease-out;
}

.learn-more-button::before {
    content: " ";
    position: absolute;
    height: calc(100% + 4px);
    width: 100%;
    top: -2px;
    bottom: -2px;
    right: 10px;
    z-index: -1;
    background-color: #ffcc00;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    transform-origin: bottom right;
    -ms-transform: skew(21deg, 0deg);
    -webkit-transform: skew(21deg, 0deg);
    transform: skew(21deg, 0deg);
}

.learn-more-button::after {
    content: " ";
    position: absolute;
    height: calc(100% + 4px);
    width: 100%;
    top: -2px;
    bottom: -2px;
    left: 10px;
    z-index: -1;
    background-color: #ffcc00;
    border-radius: 8px;
    transform-origin: bottom right;
}

.learn-more-button:hover::before {
    background-color: white;
    border: 2px solid #ffcc00;
}

.learn-more-button:hover::after {
    background-color: white;
    border: 2px solid #ffcc00;
    border-left: none;
}

.join-us-today-button {
    @apply px-[70px] py-[15px] relative text-text  text-lg font-bold transition-colors duration-150 ease-out;
}

.join-us-today-button::before {
    content: " ";
    position: absolute;
    height: calc(100% + 4px);
    width: 100%;
    top: -2px;
    bottom: -2px;
    left: 12px;
    z-index: -1;
    background-color: #ffcc00;
    border-radius: 8px;
    border-top-right-radius: 0px;
    transform-origin: bottom right;
    -ms-transform: skew(21deg, 0deg);
    -webkit-transform: skew(21deg, 0deg);
    transform: skew(21deg, 0deg);
}

.join-us-today-button::after {
    content: " ";
    position: absolute;
    height: calc(100% + 4px);
    width: 100%;
    top: -2px;
    bottom: -2px;
    right: 10px;
    z-index: -1;
    background-color: #ffcc00;
    border-radius: 8px;
    z-index: -2;
    transform-origin: bottom right;
}

.join-us-today-button:hover::before {
    background-color: white;
    border: 2px solid #ffcc00;
    border-left: none;
}

.join-us-today-button:hover::after {
    background-color: white;
    border: 2px solid #ffcc00;
}

@media (min-width: 786px) {
    .join-us-today-button::before,
    .join-us-today-button::after {
        background-color: #222;
    }

    .join-us-today-button {
        color: #ffcc00;
    }
}
