@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --font-sans: "AvenirNextLTPro";
    --font-display: "DrukWide";
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  html {
    @apply w-full h-full overflow-x-hidden;
  }

  body {
    @apply overflow-x-hidden bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }

  body.lock {
    @apply overflow-y-hidden;
  }

  a {
    cursor: pointer;
  }
}

.react-multi-carousel-item {
  display: flex;
}

.react-multi-carousel-item.react-multi-carousel-item--active {
  display: flex;
}

.react-multi-carousel-item--active + .hero-carousel-item {
  @apply -translate-x-[15vw];
}

.react-multi-carousel-item--active ~ .hero-carousel-item {
  @apply -translate-x-[15vw];
}

.hero-carousel-item:has(+ .react-multi-carousel-item--active) {
  @apply translate-x-[15vw];
}

.hero-carousel-item {
  @apply w-full h-[50vh];
}

.hero-carousel {
  position: unset !important;
  z-index: 10;
}

.hero-carousel-button {
  @apply w-[37px] h-[37px] flex items-center justify-center absolute -bottom-[60px] bg-white rounded-full;
}

.hero-dots {
  @apply absolute bottom-[-43px] w-min left-[25px] md:left-[43px];
}

.leave-a-review-container {
  @apply bg-white  relative mt-6 md:mt-0  px-[15px] py-[16px] md:px-[29px] md:py-[33px] rounded-[10px];
}

.leave-a-review-button {
  @apply absolute block lg:hidden font-bold -bottom-[10px] -right-[15px] rounded-sm  bg-brand px-8 py-3 -skew-x-[21deg] text-[#222];
}

/* .leave-a-review-container:hover .leave-a-review-button {
  @apply animate-down-and-right lg:inline-block;
} */
/* .leave-a-review-container:hover > div {
  @apply py-4 transition-all duration-300 ease-in;
} */

.save-the-date-carousel {
  @apply pb-24 lg:pb-[10px];
  position: unset !important;
  align-items: stretch;
  /* padding-bottom: 10px; */
}

@keyframes slide {
  0% {
    transform: translateX(0);
    /* left: 0; */
  }
  100% {
    transform: translateX(-100%);
    /* left: -100%; */
  }
}

.lock {
  @apply overflow-hidden;
}

.animated {
  animation: 5s slide infinite linear;
}

.gallery img {
  @apply object-cover object-center;
}

.gallery.mobile img {
  @apply h-full;
}

.terms-and-conditions {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  /* padding: 140px; */
  @apply lg:p-[140px];
}

.terms-and-conditions p {
  @apply mb-1;
}

.terms-and-conditions h3 {
  @apply mt-4 mb-6 text-xl font-semibold;
}

.rich-text p {
  @apply lg:mt-[27px] space-y-[19px] text-xs md:text-base lg:text-lg pb-6;
}

.order-table {
  @apply w-full divide-y-2 px-[46px];
}

.order-table tr {
  @apply grid  lg:px-[46px] grid-cols-1 lg:grid-cols-4 py-[33px];
}

.order-table tr td {
  @apply flex items-center justify-between py-[15px] lg:py-0  lg:justify-end;
}

.order-table tr td:first-of-type {
  @apply flex-col items-start justify-between lg:justify-start lg:flex-row lg:items-center gap-[33px];
}

.order-table tbody {
  @apply font-semibold divide-y-2;
}

.hero {
  min-height: calc(100vh - 85px);
  /* min-height: 100vh; */
}

.gradient::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0.5));
}

.text-shadow {
  /* box-shadow: ; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.mouse {
  width: 22.22px;
  height: 40px;
  border: 3px solid #fff;
  border-radius: 60px;
  position: relative;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

@media (min-width: 768px) {
  .mouse {
    height: 60px;
    width: 33.33px;
  }

  @keyframes wheel {
    to {
      opacity: 0;
      top: 40px;
    }
  }

  @-webkit-keyframes wheel {
    to {
      opacity: 0;
      top: 40px;
    }
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 25px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 25px;
  }
}

.x-scroll {
  -ms-overflow-style: none;
}

.x-scroll::-webkit-scrollbar {
  width: 0;
}

.header-shadow {
  webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.75);
}

.cart-table thead,
.cart-table tbody {
  @apply px-[30px]  lg:px-[90px];
}

.cart-table thead tr,
.cart-table tbody tr {
  @apply grid grid-cols-[300px_150px_auto_150px] lg:grid-cols-[1fr_250px_250px_200px] w-full;
}

.cart-table td {
  @apply justify-self-center;
}

.cart-table td:first-of-type {
  @apply justify-self-start;
}

.cart-table td:last-of-type {
  @apply justify-self-end;
}

.cart-table tbody td {
  @apply px-[20px] self-center pt-[23px] pb-[19px];
}

.cart-table thead td {
  @apply p-[20px];
}

.large-shadow {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ffcc00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffcc00 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}

.blds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  /* margin: 8px; */
  border: 4px solid #222;
  border-radius: 50%;
  animation: blds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #222 transparent transparent transparent;
}

.blds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.blds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.blds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes blds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal {
  @apply z-[99999] bg-white border-none rounded-[10px] p-0 relative max-w-[800px] mx-auto h-[95vh];
}

.dp-calendar {
  right: 0;
}

.text-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

li {
  @apply list-disc;
}

td button[aria-selected="true"] {
  @apply text-white hover:text-white;
}

.blog-content h1 {
  @apply text-2xl font-display lg:text-3xl;
}

.blog-content h2 {
  @apply text-xl font-display lg:text-2xl;
}

.blog-content h3,
.blog-content h4 {
  @apply text-xl font-semibold lg:text-2xl;
}

.blog-content ul {
  @apply list-disc;
}

strong {
  @apply font-semibold;
}

i,
em {
  @apply italic;
}

.blog-content a {
  @apply font-semibold text-blue-600 underline;
}

.blog-content ol {
  @apply list-decimal list-outside;
}

.blog-content li {
  @apply my-2;
}

.privacy-policy {
  @apply my-6;
}

.privacy-policy h1 + p,
.privacy-policy h2 + p,
.privacy-policy h3 + p {
  @apply mb-4;
}

.privacy-policy h2,
h1,
h3 {
  @apply text-lg font-semibold;
}

.privacy-policy ul {
  @apply mb-4 ml-4;
}
