@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-BoldItalic-Trial.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
    /*  swap*/
    font-display: swap;
}

@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-Bold-Trial.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-HeavyItalic-Trial.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-Heavy-Trial.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-MediumItalic-Trial.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-Medium-Trial.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-SuperItalic-Trial.otf") format("opentype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "DrukWide";
    src: url("/fonts/DrukWide-Super-Trial.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-BoldIt.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-Demi.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-DemiIt.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-Heavy.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-HeavyIt.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-It.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-MediumIt.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-UltLt.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-UltLtIt.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
